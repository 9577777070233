import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import { BUTTONS_ACTION } from '_utils/constants/service-order'

export const useDataSanatize = () => {
  const canApproveBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.APPROVE_BUDGET,
    isBudgetRelated: true,
  })

  const canApproveInnerBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.APPROVE_INNER_BUDGET,
    isBudgetRelated: true,
  })

  const canCancelApproval = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.CANCEL_APPROVAL,
    isBudgetRelated: true,
  })

  const canCancelReview = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.CANCEL_REVIEW,
    isBudgetRelated: true,
  })

  const canReproveBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.REPROVE_BUDGET,
    isBudgetRelated: true,
  })

  const canReproveInnerBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.REPROVE_INNER_BUDGET,
    isBudgetRelated: true,
  })

  const canReturnBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.RETURN_BUDGET,
    isBudgetRelated: true,
  })

  const canReviewBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.REVIEW_BUDGET,
    isBudgetRelated: true,
  })

  const canIntermediaryReviewBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.INTERMEDIARY_REVIEW_BUDGET,
    isBudgetRelated: true,
  })

  const canIntermediaryRefuseBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.INTERMEDIARY_REFUSE_BUDGET,
    isBudgetRelated: true,
  })

  const canSendBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SEND_BUDGET,
  })

  return {
    canApproveBudget,
    canApproveInnerBudget,
    canCancelApproval,
    canCancelReview,
    canReproveBudget,
    canReproveInnerBudget,
    canReturnBudget,
    canReviewBudget,
    canIntermediaryReviewBudget,
    canIntermediaryRefuseBudget,
    canSendBudget,
  }
}
