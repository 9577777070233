import { round } from '_/views/finance/utils/functions'

export const INITIAL_STATE = {
  diagnosis: '',
  proposedSolution: '',
  budgetItems: [],
  acceptsInstallment: false,
  installments: '',
  acceptsWarranty: false,
  warrantyMonths: '',
  visitHappened: false,
  totalPriceTradesman: undefined,
  totalPrice: undefined,
  totalPriceServiceProvider: undefined,
  liquidValue: undefined,
  referaCompletionPercentage: undefined,
  referaCompletionPercentageValue: undefined,
  agencyCompletionPercentage: undefined,
  agencyCompletionPercentageValue: undefined,
  withholdingTax: undefined,
  withholdingTaxValue: undefined,
  originalFinancialIncome: undefined,
  financialIncome: undefined,
  financialIncomeValue: undefined,
  finalPrice: undefined,
  budgetPictures: [],
  personPaying: '',
  personPayingEmail: '',
  personPayingWhatsapp: '',
  budgetItemsToRemove: [],
  budgetItemsToEdit: [],
  budgetPicturesToRemove: [],
  budgetPicturesToAdd: [],
  blockFinanceTransfers: false,
  additionalTakeRate: undefined,
  additionalTakeRatePerc: undefined,
  priceAgency: undefined,
  priceRefera: undefined,
  warrantyValue: undefined,
  referaRegularTax: undefined,
  referaRegularTaxPercentage: undefined,
  revenueShare: undefined,
}

const getTradesmanPrice = budgetItems => {
  if (!budgetItems?.length) {
    return 0
  }
  if (budgetItems.length === 1) {
    return Number(budgetItems[0].price)
  }

  const price = budgetItems.reduce((acc, current) => {
    if (current?.price) {
      if (acc?.price) {
        return Number(acc.price) + Number(current.price)
      }

      return Number(acc) + Number(current.price)
    }

    return Number(acc?.price || acc)
  })

  const formattedPrice = price ? price.toFixed(2)?.replace(/[.,]00$/, '') : 0
  return Number(formattedPrice)
}

const getTotalPrice = (state, value) => {
  const { referaCompletionPercentage, agencyCompletionPercentage, financialIncome } = state

  const referaPercentage = (value * referaCompletionPercentage) / 100
  const agencyPercentage = (value * agencyCompletionPercentage) / 100
  const financialIncomePercentage = (value * financialIncome) / 100

  return Number(
    (Number(value) + referaPercentage + agencyPercentage + financialIncomePercentage)
      .toFixed(2)
      ?.replace(/[.,]00$/, '')
  )
}

const updateFinancialValues = (state, payload, newPriceTradesman) => {
  const {
    referaCompletionPercentage,
    agencyCompletionPercentage,
    referaRegularTaxPercentage,
    additionalTakeRatePerc,
  } = state

  const referaCompletionPercentageValue = round(
    Number(newPriceTradesman * referaCompletionPercentage) / 100,
    2
  )
  const agencyCompletionPercentageValue = round(
    Number(newPriceTradesman * agencyCompletionPercentage) / 100,
    2
  )

  const additionalTakeRate = round(Number(newPriceTradesman * additionalTakeRatePerc) / 100, 2)
  const referaRegularTax = round(Number(newPriceTradesman * referaRegularTaxPercentage) / 100, 2)

  return {
    totalPriceTradesman: newPriceTradesman,
    referaCompletionPercentageValue,
    agencyCompletionPercentageValue,
    additionalTakeRate,
    referaRegularTax,
    ...payload,
  }
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_BUDGET_ITEMS = 'UPDATE_BUDGET_ITEMS'
export const ADD_BUDGET_ITEM = 'ADD_BUDGET_ITEM'
export const REMOVE_BUDGET_ITEM = 'REMOVE_BUDGET_ITEM'
export const REMOVE_BUDGET_PICTURES = 'REMOVE_BUDGET_PICTURES'
export const ADD_BUDGET_PICTURES = 'ADD_BUDGET_PICTURES'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case UPDATE_BUDGET_ITEMS: {
      const { budgetItems, budgetItemsToEdit, withholdingTax } = state
      const newBudgetItems = [...budgetItems]

      let payload = {}

      newBudgetItems[action.elementIndex] = {
        ...newBudgetItems[action.elementIndex],
        ...action.payload,
      }

      const newPriceTradesman = getTradesmanPrice(newBudgetItems)

      payload = updateFinancialValues(state, payload, newPriceTradesman)

      payload = {
        ...payload,
        budgetItems: [...newBudgetItems],
        totalPriceTradesman: newPriceTradesman,
        withholdingTax,
        budgetItemsToEdit,
      }

      if (
        'id' in newBudgetItems[action.elementIndex] &&
        !budgetItemsToEdit.some(item => item === newBudgetItems[action.elementIndex].id)
      ) {
        payload.budgetItemsToEdit.push(newBudgetItems[action.elementIndex].id)
      }

      return {
        ...state,
        ...payload,
      }
    }

    case ADD_BUDGET_ITEM: {
      const newBudgetItems = [...state.budgetItems]

      newBudgetItems.push({
        description: '',
        price: '',
      })

      return {
        ...state,
        budgetItems: newBudgetItems,
      }
    }

    case REMOVE_BUDGET_ITEM: {
      const { itemId, itemIndex } = action.payload
      const itemsToRemove = [...state.budgetItemsToRemove]

      let budgetItems = []
      let payload = {}

      if (itemId) {
        budgetItems = state.budgetItems.filter(item => item.id !== Number(action.payload.itemId))
        itemsToRemove.push(Number(action.payload.itemId))
      } else if (itemIndex) {
        budgetItems = [...state.budgetItems]

        budgetItems.splice(itemIndex, 1)
      }

      const newPriceTradesman = getTradesmanPrice(budgetItems)

      const newTotalPrice = getTotalPrice(state, newPriceTradesman)
      payload = updateFinancialValues(state, payload, newPriceTradesman)
      payload = {
        ...payload,
        budgetItems,
        budgetItemsToRemove: itemsToRemove,
        totalPriceTradesman: newPriceTradesman,
        totalPrice: newTotalPrice,
      }

      if (itemId && state.budgetItemsToEdit.some(item => item === Number(itemId))) {
        const newBudgetItemsToEdit = state.budgetItemsToEdit.filter(item => item !== Number(itemId))

        payload = {
          ...payload,
          budgetItemsToEdit: newBudgetItemsToEdit,
        }
      }

      return {
        ...state,
        ...payload,
      }
    }

    case REMOVE_BUDGET_PICTURES: {
      const { attachmentIndex } = action.payload
      const { budgetPictures, budgetPicturesToRemove, budgetPicturesToAdd } = state
      const attachment = budgetPictures.find(picture => picture.id === attachmentIndex)

      if (attachment) {
        const picturesToRemove = [...budgetPicturesToRemove, attachment.id]

        const newPictures = budgetPictures.filter(picture => picture.id !== attachment.id)
        const toAddRemoval = budgetPicturesToAdd.filter(picture => picture.id !== attachment.id)

        return {
          ...state,
          budgetPicturesToRemove: picturesToRemove,
          budgetPictures: newPictures,
          budgetPicturesToAdd: toAddRemoval,
        }
      }
      return state
    }

    case ADD_BUDGET_PICTURES: {
      const { budgetPictures, budgetPicturesToAdd } = state
      const { picture } = action.payload

      const newBudgetPictures = [...budgetPictures, picture].flat()
      const picturesToAdd = [...budgetPicturesToAdd, picture].flat()

      return {
        ...state,
        budgetPictures: newBudgetPictures,
        budgetPicturesToAdd: picturesToAdd,
      }
    }
    default:
      return state
  }
}
