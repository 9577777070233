import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing(20),
  },
  box: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing(30),
    marginTop: spacing(20),
  },
  line: {
    width: '100%',
    display: 'flex',
    gap: spacing(30),

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: spacing(30),
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),
    width: '100%',
  },
  select: {
    width: '100%',
    margin: 0,
    height: '100% !important',
    marginTop: spacing(-15),

    '& .MuiFormLabel-root': {
      fontSize: `${spacing(16)} !important`,
      color: palette.gray[64],
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  label: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
  title: {
    fontSize: spacing(16),
    lineHeight: spacing(16),
    color: palette.gray[64],
    fontWeight: 'bold',
  },

  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: palette.primary.main,
      color: palette.gray[4],
    },
    '& .MuiTooltip-arrow': {
      color: palette.primary.main,
    },
  },
  tooltipBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  li: {
    width: '100%',
  },
  svgIcon: {
    width: '40px',
    height: '40px',
  },

  selectHeightCompensation: {
    marginBottom: spacing(-2),
  },
  errorMessage: {
    color: `${palette.error.main} !important`,
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
    display: 'flex !important',
    marginTop: spacing(10),
  },

  required: {
    marginLeft: '5px',
    color: 'red',
    fontSize: '16px',
  },
}))
