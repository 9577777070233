import React, { useCallback, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Loader } from '@refera/ui-web'

import { returnBudgetProvider } from '_modules/budget/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import Button from '_components/button'
import TextField from '_components/textfield'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import { getServiceOrder } from '_/modules/service-orders/actions'
import useRolePermission from '_/hooks/use-role-permission'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { useToast } from '_/hooks/use-toast'
import { openWhatsApp } from '_/utils/open-whatsApp'
import * as Modal from '_components/modal/generic-modal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const ReturnToProvider = ({ isOpen, closeModal, serviceOrderId, budgetId }) => {
  const styles = useStyles()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [returnToProvider, setReturnToProvider] = useState('')
  const [sendWhatsapp, setSendWhatsapp] = useState(false)
  const { isSAAS } = useRolePermission()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const refetchServiceOrder = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const handleProvider = e => {
    setReturnToProvider(e.target.value)
  }
  const handleWhatsapp = e => {
    setSendWhatsapp(e.target.checked)
  }

  const handleReturnToProvider = useCallback(
    async isOriginalBudget => {
      setIsLoading(true)

      await dispatch(
        returnBudgetProvider(serviceOrderId, budgetId, {
          reason_devolution: returnToProvider,
          send_whatsapp: sendWhatsapp,
          is_original_budget: isOriginalBudget,
        })
      )
        .then(() => {
          refetchServiceOrder()
          dispatch(getHistoryLogs(serviceOrderId))
          if (sendWhatsapp && serviceOrder?.tradesmanPhone) {
            openWhatsApp(serviceOrder?.tradesmanPhone)
          }
          return navigate(`/chamado/${serviceOrderId}`)
        })
        .catch(() => {
          showToast({ type: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [
      isSAAS,
      serviceOrderId,
      budgetId,
      returnToProvider,
      sendWhatsapp,
      dispatch,
      refetchServiceOrder,
      serviceOrder?.isReferaService,
    ]
  )

  return (
    <>
      <Loader hasBackdrop open={isLoading} />
      <Dialog
        open={isOpen}
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
          Orçamento Devolvido ao Prestador
        </DialogTitle>
        <DialogContent className={styles.container}>
          <Grid className={styles.empty}>
            <Typography className={styles.textHeader}>Motivo</Typography>
          </Grid>
          <TextField
            className={classes.inputComment}
            variant="outlined"
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            rows={4}
            name="reason_devolution"
            value={returnToProvider}
            onChange={handleProvider}
          />
          <ConfidentialDataWarning style={{ marginTop: 8 }} />
          <FormControlLabel
            control={
              <Checkbox name="send_whatsapp" value={sendWhatsapp} onChange={handleWhatsapp} />
            }
            label={<Typography color="#434343">Comunicar por WhatsApp</Typography>}
          />
        </DialogContent>

        <DialogActions className={styles.dialogAction}>
          <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
            Cancelar
          </Button>
          <Button
            className={styles.button}
            onClick={() => setOpenConfirmationModal(true)}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {openConfirmationModal && (
        <Modal.Root open={openConfirmationModal} close={() => setOpenConfirmationModal(false)}>
          <Modal.Content>
            <Modal.WarningIcon />
            <Modal.SubTitle className={styles.subtitle}>Devolver ao prestador</Modal.SubTitle>
            <Modal.Text>Selecione a maneira de devolver o orçamento ao prestador.</Modal.Text>
          </Modal.Content>

          <Modal.Actions>
            <Modal.ButtonRed onClick={() => setOpenConfirmationModal(false)}>
              Cancelar
            </Modal.ButtonRed>
            <Modal.ButtonBlue onClick={() => handleReturnToProvider(true)}>
              Orçamento original
            </Modal.ButtonBlue>
            <Modal.ButtonFullBlue onClick={() => handleReturnToProvider(false)}>
              Orçamento atual
            </Modal.ButtonFullBlue>
          </Modal.Actions>
        </Modal.Root>
      )}
    </>
  )
}

ReturnToProvider.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(ReturnToProvider)
