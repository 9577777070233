import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  titleBlue: {
    color: '#1671ED',
    cursor: 'move',
    maxWidth: '584px',
    minWidth: '390px',
  },
  dialog: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    maxWidth: '584px',
    minWidth: '390px',
    height: '100%',
    padding: '20px',
    flexDirection: 'column',
    borderTop: '2px solid #E3E3E3',
    overflow: 'hidden',
  },
  dialogAction: {
    padding: '20px 30px',
    maxWidth: '584px',
    minWidth: '390px',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  statusField: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '40px',
    width: '90px',
    padding: '10px, 16px, 10px, 16px',
  },
  footer: {
    padding: '30px 30px 25px 0px',
  },
  icon: {
    fontSize: '22px',
    paddingRight: '2px',
    paddingBottom: '4px',
  },
  iconButton: {
    height: '80px',
    width: '80px',
  },
  textHeader: {
    fontSize: '12px',
    color: '#141414',
    marginTop: '5px',
    textAlign: 'center',
    lineHeight: '32px',
  },
  textSubheader: {
    fontSize: '16px',
    color: '#696969',
    marginTop: '10px',
    textAlign: 'center',
  },
  muiButton: {
    height: '40px',
    width: 'auto',
    padding: '10px 16.5px',
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: '500',
  },
  muiArrow: {
    height: '40px',
    width: 'auto',
    borderRadius: '20px',
  },
  empty: {
    height: '39px',
    width: '20px',
    marginLeft: '4px',
    margintop: '72px',
  },
  inputComment: {
    '& .MuiOutlinedInput-multiline': {
      postion: 'absolute',
      boxSizing: 'border-box',
      alignItems: 'baseline',
      minHeight: 'auto',
      height: '104px',
      maxWidth: '536px',
      minWidth: '390px',
      marginLeft: '4px',
    },
  },
  subtitle: {
    fontSize: '20px',
    color: '#696969',
    fontWeight: '400',
  },
}))
