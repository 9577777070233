import React, { useCallback, useState } from 'react'
import * as Modal from '_components/modal/generic-modal'
import { Loader } from '@refera/ui-web'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { sendBudgetEmail, sendBudgetWhatsapp } from '_/modules/budget/actions'
import { getHistoryLogs } from '_/modules/history-logs/actions'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { useParams } from '@reach/router'
import { openWhatsApp, openWhatsAppWithMessage } from '_/utils/open-whatsApp'
import { getMessagingByServiceOrderId } from '_/modules/mensageria/actions'
import { ROLE_GROUP_CHOICES } from '_/utils/constants/service-order'
import { getBudgetByIdSelector } from '_/modules/budget/selectors'
import { numbersValid } from '_/utils/helpers'

export default function SendBudgetModal({ open, onClose }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { serviceOrderId, budgetId } = useParams()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const activeBudget = useSelector(getBudgetByIdSelector(serviceOrder.activeBudget))

  const [loading, setLoading] = useState(false)

  const onEmailSubmit = useCallback(async () => {
    const budgetValue = activeBudget?.toJS()
    if (!budgetValue?.budgetPayer?.[0]?.personPayingEmail) {
      return showToast({
        type: 'warning',
        message:
          'Não foi digitado o "Email do responsável pelo pagamento". Clique em "Dados do pagador" e digite esta informação.',
      })
    }

    setLoading(true)
    await dispatch(sendBudgetEmail(serviceOrderId, budgetId))
      .then(async () => {
        dispatch(getHistoryLogs(serviceOrderId))
        onClose()
        showToast({ type: 'success' })
      })
      .catch(error => {
        const errorMessage = formatErrorMessage(error)
        showToast({ message: errorMessage, type: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })

    return null
  }, [onClose, serviceOrderId, budgetId])

  const onSendWaSubmit = useCallback(async () => {
    const budgetValue = activeBudget?.toJS()
    if (!budgetValue?.budgetPayer?.[0]?.whatsappResponsible) {
      return showToast({
        type: 'warning',
        message:
          'Não foi digitado o "WhatsApp do responsável pelo pagamento". Clique em "Dados do pagador" e digite esta informação.',
      })
    }

    if (!numbersValid(budgetValue?.budgetPayer[0]?.whatsappResponsible)) {
      return showToast({
        type: 'warning',
        message: 'Pagador com número de contato inválido.',
      })
    }

    setLoading(true)

    await dispatch(sendBudgetWhatsapp(serviceOrderId, budgetId))
      .then(async () => {
        dispatch(getHistoryLogs(serviceOrderId))
        showToast({ type: 'success' })

        await dispatch(
          getMessagingByServiceOrderId({
            serviceOrderId,
            destination: ROLE_GROUP_CHOICES.PAYER_GROUP,
            stepStatus: serviceOrder?.stepStatus,
          })
        )
          .then(response => {
            if (!response || !response?.whatsappText) {
              return openWhatsApp(budgetValue?.budgetPayer[0]?.whatsappResponsible)
            }

            if (budgetValue?.budgetPayer[0]?.whatsappResponsible && response?.whatsappText) {
              return openWhatsAppWithMessage({
                phone: budgetValue?.budgetPayer[0]?.whatsappResponsible,
                message: response.whatsappText,
              })
            }
            onClose()
            return null
          })
          .catch(() => {
            console.error('Erro na requisição de procurar um registro de mensageria.')
          })
      })
      .catch(error => {
        const errorMessage = formatErrorMessage(error)
        showToast({ message: errorMessage, type: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })

    return null
  }, [onClose, serviceOrderId, budgetId, activeBudget])

  return (
    <Modal.Root open={open} onClose={onClose} maxWidth="md">
      <Modal.TitleModal title="Enviar orçamento" />

      <Modal.Content className={styles.container}>
        <Modal.SubTitle>Selecione por onde deseja enviar o orçamento ao pagador.</Modal.SubTitle>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose} disabled={loading}>
          Fechar
        </Modal.ButtonRed>
        <Modal.ButtonBlue form="formEmail" onClick={() => onSendWaSubmit()} disabled={loading}>
          Enviar por WhatsApp
        </Modal.ButtonBlue>
        <Modal.ButtonFullBlue form="formEmail" onClick={() => onEmailSubmit()} disabled={loading}>
          Enviar por E-mail
        </Modal.ButtonFullBlue>
      </Modal.Actions>
      <Loader hasBackdrop open={loading} />
    </Modal.Root>
  )
}
