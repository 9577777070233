/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, FormHelperText, Tooltip, Typography } from '@mui/material'
import { InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core'
import classNames from 'classnames'
import { menuProps } from '_/views/step-notification-message-setup/utils'
import NewProviderIcon from '_assets/icons/new-provider.svg'

import useStyles from '../styles'
import { LEVEL_OPTIONS } from '_/views/provider/components/ProviderReferaDetailsModal/constants'
import Svg from '_/components/svg'

export default function Select({
  options,
  label,
  name,
  required = false,
  getValue,
  getLabel = () => {},
  getKey,
  defaultValue = '',
  disabled = false,
  style = {},
  containerClasses,
  labelClasses,
  selectClasses,
  ...rest
}) {
  const { control, errors, setValue } = useFormContext()

  const styles = useStyles()

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  const generateOptionKey = useCallback(
    item => {
      return getKey ? getKey(item) : crypto.randomUUID()
    },
    [getKey]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '80px',
        justifyContent: 'center',
        ...style,
      }}
      className={classNames(styles.select, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        error={!!errors[name]}
        style={{ color: errors[name] ? '#C90000' : null }}
        className={classNames(styles.selectHeightCompensation, labelClasses ?? null)}
        shrink
      >
        {label}
        {required && <span className={styles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ?? ''}
        rules={{ required: { value: required, message: 'Esse campo é obrigatório' } }}
        as={
          <MuiSelect
            MenuProps={menuProps}
            displayEmpty
            labelId={`${name}-label`}
            required={required}
            disabled={disabled}
            error={!!errors[name]}
            className={selectClasses ?? null}
            {...rest}
          >
            {options?.length ? (
              options.map(item => {
                const { level, servicesCount, warrantyPercentage, budgetConversion } =
                  item?.score || {}

                const levelLabel = level
                  ? LEVEL_OPTIONS.find(itemLevel => itemLevel.value === level)
                  : null

                if (!level || !levelLabel) {
                  return (
                    <Tooltip
                      key={generateOptionKey(item)}
                      placement="left"
                      arrow
                      classes={{ popper: styles.tooltip }}
                      title={
                        <Box className={[styles.tooltipBox, styles.center]}>
                          <Svg className={styles.svgIcon} icon={NewProviderIcon} />
                          <Typography variant="body2">Prestador novo</Typography>
                        </Box>
                      }
                    >
                      <MenuItem key={generateOptionKey(item)} value={getValue(item)}>
                        {getLabel(item) || getValue(item)}
                      </MenuItem>
                    </Tooltip>
                  )
                }

                return (
                  <Tooltip
                    key={generateOptionKey(item)}
                    placement="left"
                    arrow
                    classes={{ popper: styles.tooltip }}
                    title={
                      <Box className={styles.tooltipBox}>
                        <Typography variant="body2">
                          <strong>Classificação:</strong> {levelLabel.label}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Qtd. de serviços na Refera:</strong> {servicesCount}
                        </Typography>
                        <Typography variant="body2">
                          <strong>% de garantias:</strong> {warrantyPercentage}%
                        </Typography>
                        <Typography variant="body2">
                          <strong>Conv. de orçamentos:</strong> {budgetConversion}%
                        </Typography>
                      </Box>
                    }
                  >
                    <MenuItem key={generateOptionKey(item)} value={getValue(item)}>
                      {getLabel(item) || getValue(item)}
                    </MenuItem>
                  </Tooltip>
                )
              })
            ) : (
              <MenuItem value="" disabled>
                Nenhum item encontrado
              </MenuItem>
            )}
          </MuiSelect>
        }
      />
      <FormHelperText className={styles.errorMessage} error={!!errors[name]}>
        {errors[name]?.message}
      </FormHelperText>
    </div>
  )
}
